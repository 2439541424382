import React from "react"
import LocalizedLink from "../components/localizedLink"
import ImageLogo from "../components/imageLogo"
import useTranslations from "../components/useTranslations"

const Seed = ({ seed }) => {
  return (
    <LocalizedLink to={seed.slug} contentFilePath={seed.contentFilePath}>
    <div className="grid grid-col m-2 p-6 bg-acorn-brown rounded shadow-2xl min-h-196 text-center justify-items-center transform transition duration-500 hover:scale-105">
        <div className="mt-4 w-48 h-52 overflow-hidden rounded-full border-2 border-acorn-brown2 bg-mint-green3">
          <ImageLogo />
        </div>
          <h2 className="text-2xl font-bold text-gray-800">{seed.title}</h2>
        </div>
    </LocalizedLink>
    
  )
}


const SeedList = ({ seeds, showHeading }) => {
  
  const { seedsline } = useTranslations()
  return (
    <section className="">
      {showHeading && (
        <h2 className="text-center font-normal text-accent tracking-widestest border-b border-subtle border-mint-green4 mb-8 pb-4">
          {seedsline}
        </h2>
      )}
      <div className="grid lg:grid-cols-3 md:grid-cols-2">
          
          {seeds.map(( node ) => {
            const seed = {
              slug: node.frontmatter.slug,
              title: node.frontmatter.title,
              date: node.frontmatter.date,
              tags: node.frontmatter.tags,
              contentFilePath: node.internal.contentFilePath
            }
            return <Seed key={node.id} seed={seed}/>
          })}
          
      </div>
      
      {showHeading &&
        (
          <div className="mt-8 border-t border-subtle border-mint-green4" aria-label="hidden">
          </div>
        )
      }

    </section>
  )
}

export default SeedList
