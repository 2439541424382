import React from "react"
import LocalizedLink from "../components/localizedLink"
import ImageLogo from "../components/imageLogo"
import useTranslations from "../components/useTranslations"

const Leaf = ({ leaf }) => {
  return (
    <LocalizedLink to={leaf.slug} contentFilePath={leaf.contentFilePath}>
      <div className="grid grid-col m-2 p-6 bg-mint-green rounded shadow-2xl min-h-196 text-center justify-items-center transform transition duration-500 hover:scale-105">
        <div className="mt-4 w-48 h-52 overflow-hidden rounded-full border-2 border-mint-green4 bg-mint-green3">
          <ImageLogo />
        </div>
          <h2 className="text-2xl font-bold text-gray-800">{leaf.title}</h2>
      </div>
    </LocalizedLink>
    
  )
}


const LeafList = ({ leaves, showHeading }) => {
  
  const { leavesline } = useTranslations()
  return (
    <section className="">
      {showHeading && (
        <h2 className="text-center font-normal text-accent tracking-widestest border-b border-subtle border-mint-green4 mb-8 py-4">
          {leavesline}
        </h2>
      )}
      <div className="grid lg:grid-cols-3 md:grid-cols-2">
          
          {leaves.map(( node ) => {
            const leaf = {
              slug: node.frontmatter.slug,
              title: node.frontmatter.title,
              date: node.frontmatter.date,
              tags: node.frontmatter.tags,
              contentFilePath: node.internal.contentFilePath
            }
            return <Leaf key={node.id} leaf={leaf}/>
          })}
          
      </div>
      
      {showHeading &&
        (
          <div className="mt-8 border-t border-subtle border-mint-green4" aria-label="hidden">
          </div>
        )
      }

    </section>
  )
}

export default LeafList
