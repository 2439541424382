import * as React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import LeafList from "../components/leafList"
import SeedList from "../components/seedList"
import ImageLogo from "../components/imageLogo"
import useTranslations from "../components/useTranslations"

const BlogIndex = ({ location, pageContext, data }) => {

  const { subline, leavesCount, seedsCount, german, english, statistic } = useTranslations()

  return (
    <>
      <section className="p-4 flex flex-col items-center">
        
        <div className="w-60 overflow-hidden rounded-full mb-6 border-2 border-mint-green bg-white">
          <ImageLogo />
        </div>
        
        <div
          className="text-5xl md:text-6xl font-bold text-center max-w-3xl mb-4 relative"
        >
          <div
            className="absolute top-10 opacity-50 transform left-1/2 -translate-x-1/2 text-mint-green4 z-0 w-full"
          >
            <svg
              className="max-w-xs md:max-w-lg mx-auto w-full"
              viewBox="0 0 625 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 46c1.035 2.276 1.035 2.276 1.036 2.275h.002l.011-.006.048-.021.198-.089c.176-.08.443-.197.796-.353.707-.31 1.763-.767 3.145-1.35a406.34 406.34 0 0112.06-4.841c10.438-4.011 25.451-9.363 43.72-14.716C99.57 16.19 149.07 5.5 201 5.5v-5C148.43.5 98.43 11.312 61.61 22.1c-18.42 5.398-33.563 10.796-44.108 14.848a411.168 411.168 0 00-12.209 4.9 243.513 243.513 0 00-4.039 1.746 96.86 96.86 0 00-.268.121l-.015.007-.004.002c-.002 0-.002 0 1.033 2.276zM201 5.5c51.827 0 96.622 13.509 138.315 26.573 41.555 13.021 80.28 25.705 119.331 23.423l-.292-4.992c-37.949 2.218-75.724-10.098-117.544-23.202C299.128 14.24 253.673.5 201 .5v5zm257.646 49.996c38.873-2.272 80.4-14.874 112.112-26.845a615.88 615.88 0 0038.831-16.199 484.157 484.157 0 0011.08-5.243 292.1 292.1 0 003.713-1.852l.197-.1.05-.027.014-.006.004-.002c.001 0 .001-.001-1.147-2.222L622.351.78h-.002l-.011.006a611.127 611.127 0 01-53.346 23.187c-31.538 11.905-72.511 24.303-110.638 26.531l.292 4.992z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div className="relative">Lyrical Oak Whisper</div>
        </div>
        <h1 className="text-xl font-bold text-center mb-4">
          Feel The Leaves And Share The Seeds
        </h1>
        <p className="text-xl font-light text-justify max-w-3xl mt-8 mb-4">
          {subline}
        </p>
      </section>
      <section className="body-font">
        <div className="container px-2 py-4 mx-auto border-t border-b border-subtle mb-4 border-mint-green4">
          <div className="flex flex-wrap w-full mb-4">
            <div className="w-full lg:mb-0 flex flex-col items-center">
              <h1 className="sm:text-lg text-xl font-medium title-font mb-2 text-center">{statistic}</h1>
              <div className="h-1 w-20 bg-mint-green rounded"></div>
            </div>
          </div>
          <div className="flex flex-wrap -m-4 text-center">
            <div className="p-4 sm:w-1/4 w-1/2">
              <Link to="/">
                <div className="bg-mint-green2 rounded-lg p-2 xl:p-6">
                    <h2 className="title-font font-medium sm:text-4xl text-3xl text-white">{data.seedsEN.totalCount}</h2>
                    <p className="leading-relaxed font-bold">{seedsCount}</p>
                    <p className="leading-relaxed">({english})</p>
                </div>
              </Link>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <Link to="/">
                <div className="bg-mint-green2 rounded-lg p-2 xl:p-6">
                    <h2 className="title-font font-medium sm:text-4xl text-3xl text-white">{data.leavesEN.totalCount}</h2>
                    <p className="leading-relaxed font-bold">{leavesCount}</p>
                    <p className="leading-relaxed">({english})</p>
                </div>
              </Link>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <Link to="/de">
                <div className="bg-mint-green2 rounded-lg p-2 xl:p-6">
                    <h2 className="title-font font-medium sm:text-4xl text-3xl text-white">{data.seedsDE.totalCount}</h2>
                    <p className="leading-relaxed font-bold">{seedsCount}</p>
                    <p className="leading-relaxed">({german})</p>
                </div>
              </Link>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <Link to="/de">
                <div className="bg-mint-green2 rounded-lg p-2 xl:p-6">
                    <h2 className="title-font font-medium sm:text-4xl text-3xl text-white">{data.leavesDE.totalCount}</h2>
                    <p className="leading-relaxed font-bold">{leavesCount}</p>
                    <p className="leading-relaxed">({german})</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <SeedList seeds={data.seeds.nodes} pageContext={pageContext} showHeading />
      <LeafList leaves={data.leaves.nodes} pageContext={pageContext} showHeading />
    </>
  )
}

export default BlogIndex

export const query = graphql`
    query IndexPageQuery($locale: String!, $dateFormat: String!) {
      leavesDE: allMdx(
        filter: {
          frontmatter: {
            published: { eq: true }
          }
          internal: {
            contentFilePath: { regex: "\/leaves\//" }
          }
          fields: { 
            locale: { eq: "de" } 
          }
        }
      ) {
        totalCount
      }
      seedsDE: allMdx(
        filter: {
          frontmatter: {
            published: { eq: true }
          }
          internal: {
            contentFilePath: { regex: "\/seeds\//" }
          }
          fields: { 
            locale: { eq: "de" } 
          }
        }
      ) {
        totalCount
      }
      leavesEN: allMdx(
        filter: {
          frontmatter: {
            published: { eq: true }
          }
          internal: {
            contentFilePath: { regex: "\/leaves\//" }
          }
          fields: { 
            locale: { eq: "en" } 
          }
        }
      ) {
        totalCount
      }
      seedsEN: allMdx(
        filter: {
          frontmatter: {
            published: { eq: true }
          }
          internal: {
            contentFilePath: { regex: "\/seeds\//" }
          }
          fields: { 
            locale: { eq: "en" } 
          }
        }
      ) {
        totalCount
      }
      leaves: allMdx(
          sort: { fields: [frontmatter___date], order: DESC },
          filter: {
            frontmatter: {
              published: { eq: true }
            }
            internal: {
              contentFilePath: { regex: "\/leaves\//" }
            }
            fields: { 
              locale: { eq: $locale } 
            }
          }
          limit: 20
        ) {
          nodes {
            id
            excerpt(pruneLength: 100)
            internal {
              contentFilePath
            }
            frontmatter {
              title
              date(formatString: $dateFormat)
              tags
              slug
            }
            fields {
              locale
            }
          }
          totalCount
        }
      seeds: allMdx(
          sort: { fields: [frontmatter___date], order: DESC },
          filter: {
            frontmatter: {
              published: { eq: true }
            }
            internal: {
              contentFilePath: { regex: "\/seeds\//" }
            }
            fields: { 
              locale: { eq: $locale } 
            }
          }
          limit: 20
        ) {
          nodes {
            id
            excerpt(pruneLength: 100)
            internal {
              contentFilePath
            }
            frontmatter {
              title
              date(formatString: $dateFormat)
              tags
              slug
            }
            fields {
              locale
            }
          }
          totalCount
        }
      }
    `

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />
